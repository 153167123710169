import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropertyDetail from "../../components/propertyDetail/propertyDetail";

// FIREBASE
import {
  collection,
  query,
  getDocs,
  documentId,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/config";

export default function PropertyDetailContainer() {
  const [propiedades, setPropiedades] = useState([]);
  const { id } = useParams();

  const getPropiedades = async () => {
    const q = query(
      collection(db, "propiedades"),
      where(documentId(), "==", id)
    );
    const docs = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });

    setPropiedades(docs);
  };

  useEffect(() => {
    // Realizar la consulta solo cuando cambie el ID
    getPropiedades();
  }, [id]);

  return (
    <div>
      {propiedades.map((propiedad) => (
        <div key={propiedad.id}>
          <PropertyDetail propiedades={propiedad} />
        </div>
      ))}
    </div>
  );
}
