export default function PropertyMap({ propiedades }) {
  if (!propiedades.maps) {
    return null;
  }

  const mapWithReplacedAttributes = propiedades.maps.replace(
    /(?:style|width|height)="[^"]*"/g,
    (match) => {
      if (match.includes("width")) {
        return 'width="100%"';
      } else if (match.includes("height")) {
        const heightValue = window.innerWidth >= 1024 ? "400rem" : "auto";
        return `height="${heightValue}"`;
      } else {
        return match;
      }
    }
  );

  return (
    <div className="w-full mb-4">
      <p className="text-2xl font-bold bg-primary -mx-3 pl-3 py-2">Ubicación</p>
      <div
        className="mt-3"
        dangerouslySetInnerHTML={{ __html: mapWithReplacedAttributes }}
      />
    </div>
  );
}
