import { FaBath, FaMapMarkerAlt } from "react-icons/fa";
import { FaHouse } from "react-icons/fa6";
import { IoIosBed } from "react-icons/io";
import { Link } from "react-router-dom";

export default function Property({ propiedades }) {
  return (
    <div className="mx-auto my-7 max-w-sm rounded-lg overflow-hidden shadow-md hover:shadow-lg">
      <Link to={`/propiedad/${propiedades.id}`}>
        <div>
          <img
            className="w-96 h-56 hover:transform hover:scale-105 transition-transform"
            src={propiedades.imagenPortada}
            alt={propiedades.tipo}
          />
        </div>
        <p className="absolute ml-6 mt-[-13em] bg-primary text-white px-2 py-1 rounded-md">
          {propiedades.operacion.charAt(0).toUpperCase() +
            propiedades.operacion.slice(1)}
        </p>
        <p className="absolute ml-6 mt-[-2em] bg-white text-primary px-2 py-1 rounded-t">
          {propiedades.tipo.charAt(0).toUpperCase() + propiedades.tipo.slice(1)}
        </p>
        <div className="px-6 py-4">
          <p className="font-bold text-xl text-primary">
            ${propiedades.precio} {propiedades.moneda}
          </p>

          <div className="font-bold text-xl mb-2 w-fit hover:text-primary">
            {propiedades.tipo.charAt(0).toUpperCase() +
              propiedades.tipo.slice(1)}{" "}
            en {propiedades.operacion}
          </div>

          <p className="text-gray-700 text-base flex items-center">
            <FaMapMarkerAlt className="mr-3 text-primary" />
            {propiedades.direccion}, {propiedades.ciudad}
          </p>
        </div>
        <hr />
        <div className="px-6 pt-2 pb-1 flex justify-between">
          {propiedades.ambientes && propiedades.ambientes > 0 && (
            <p className="text-base flex items-center">
              <FaHouse className="mr-2 text-primary" />
              {propiedades.ambientes > 1
                ? `${propiedades.ambientes} ambientes`
                : "1 ambiente"}
            </p>
          )}

          {propiedades.dormitorios && propiedades.dormitorios > 0 && (
            <p className="text-base flex items-center">
              <IoIosBed className="mr-2 text-primary" />
              {propiedades.dormitorios > 1
                ? `${propiedades.dormitorios} dormitorios`
                : "1 dormitorio"}
            </p>
          )}
        </div>
        <div className="px-6 pb-1 flex justify-between">
          {propiedades.baños && propiedades.baños > 0 && (
            <p className="text-base flex items-center">
              <FaBath className="mr-2 text-primary" />
              {propiedades.baños > 1 ? `${propiedades.baños} baños` : "1 baño"}
            </p>
          )}
        </div>
      </Link>
    </div>
  );
}
