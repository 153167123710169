import { useForm } from "@formspree/react";

export default function Form() {
  const [state, handleSubmit] = useForm("mzbnelko");
  if (state.succeeded) {
    return (
      <p className="text-lg text-primary font-semibold my-4">
        ¡Gracias por ponerte en contacto con nosotros!
      </p>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="lg:flex lg:flex-col lg:space-y-4 lg:text-sm lg:mr-[5.3rem]"
    >
      <input
        type="text"
        id="NombreyApellido"
        name="NombreyApellido"
        required
        placeholder="Nombre y Apellido"
        className="border border-primary rounded-sm my-1 w-full pl-1 py-1 lg:w-full"
      />

      <input
        type="email"
        required
        id="email"
        name="email"
        placeholder="Correo electrónico"
        className="border border-primary rounded-sm my-1 w-full pl-1 py-1 lg:w-full"
      />

      <input
        type="tel"
        required
        id="telefono"
        name="telefono"
        placeholder="Teléfono"
        className="border border-primary rounded-sm my-1 w-full pl-1 py-1 lg:w-full"
      />

      <textarea
        id="mensaje"
        required
        name="mensaje"
        placeholder="Escriba su mensaje aquí"
        className="border border-primary rounded-sm my-1 w-full pl-1 lg:w-full"
      />

      <button
        type="submit"
        className="bg-primary w-full py-1 rounded-sm font-semibold text-lg mb-4 flex items-center justify-center"
      >
        ENVIAR CONSULTA
      </button>
    </form>
  );
}
