import React from "react";
import Logo from "./logo.png";
import { NavLink } from "react-router-dom";

export default function NavBar() {
  return (
    <nav className="bg-primary border-orange-300 border-solid border-b fixed  w-full top-0 z-40 shadow-lg">
      <div className="max-w-screen-xl flex items-center justify-center mx-auto p-4">
        <NavLink
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src={Logo}
            className="h-16"
            alt="Logo de Natalia Chávez"
            title="Natalia Chávez"
          />
        </NavLink>
      </div>
    </nav>
  );
}
