import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { useState } from "react";

export default function SliderDetail({ className, img }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? img.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === img.length - 1 ? 0 : prevIndex + 1
    );
  };

  const baseClassname = "relative h-56 sm:h-72 md:h-96 overflow-hidden";

  return (
    <div className="relative">
      <div className={`${baseClassname} ${className}`}>
        {img.map((image, index) => (
          <div
            key={index}
            className={`absolute w-full h-full transform transition-transform duration-700 ease-in-out ${
              index === activeIndex ? "" : "translate-x-full"
            }`}
          >
            <img
              src={image}
              className="lg:w-3/5 w-full h-full object-fill lg:mx-auto"
              alt={`Imagen ${index}`}
            />
          </div>
        ))}
      </div>
      <button
        type="button"
        className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={handlePrev}
      >
        <IoIosArrowDropleftCircle className="text-primary text-4xl" />
      </button>
      <button
        type="button"
        className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={handleNext}
      >
        <IoIosArrowDroprightCircle className="text-primary text-4xl" />
      </button>
    </div>
  );
}
