import { Link } from "react-router-dom";
import Natalia from "./IMG_3629.webp";

export default function CertifiedAgent() {
  return (
    <div className="bg-[#ffd0b4] rounded-md my-16 py-1 mx-8 shadow-lg ">
      <div className="m-2 bg-white border-dotted border-2 border-[#ffd0b4] shadow-2xl rounded-md lg:flex lg:items-center">
        <div className="lg:w-1/2">
          <img
            src={Natalia}
            className="p-5 sm:p-3 mx-auto w-min lg:w-96 object-contain"
            alt="Natalia Chavez"
          />
        </div>
        <div className="lg:w-1/2 pr-6">
          <h3 className="text-slate-700 text-3xl font-bold ml-6 mt-6 xl:-ml-4">
            Contáctanos
          </h3>
          <p className="text-slate-700 mt-6 ml-6 xl:-ml-4">
            Hola, soy Natalia Chávez Martillera Publica y Perito Judicial del
            Departamento Judicial de Lomas de Zamora. En nuestra oficina nos
            comprometemos a trabajar con dedicación para acompañarte en el
            camino de conseguir tu hogar. Nuestra labor es personalizada, tanto
            en las tasaciones como en las búsquedas a realizar, por
            consiguiente, te aseguramos un gran compromiso con profesionalismo y
            dedicación.
          </p>
          <Link to="https://walink.co/5e3deb" target="_blank">
            <button
              type="button"
              className="text-white bg-primary my-6 ml-6 xl:-ml-4 hover:bg-orange-500 lg:hover:translate-y-[-0.15rem] font-semibold rounded-md text-sm px-5 py-2.5"
            >
              Contacto
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
