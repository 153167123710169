import { FaBath, FaHouse } from "react-icons/fa6";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Form from "../form/form";
import PropertyMap from "../propertyMap/propertyMap";
import PropertyDetailText from "../propertyDetailText/propertyDetailTextDefault";
import { IoIosBed, IoIosResize } from "react-icons/io";
import SliderDetail from "../sliders/sliderDetail/sliderDetail";
import { CiClock1 } from "react-icons/ci";

export default function PropertyDetail({ propiedades }) {
  return (
    <main className="mt-28 mx-3">
      <Link
        to="/"
        className="bg-primary w-fit px-2  rounded-md  font-semibold text-md flex items-center mb-3"
      >
        <IoArrowBack className="mr-1 mt-1" />
        Volver atrás
      </Link>
      <div className="lg:flex">
        <div className="lg:w-1/2">
          <SliderDetail className={`rounded-lg`} img={propiedades.imagenes} />
        </div>

        <div className="lg:w-1/2 mt-8 font-bold text-xl lg:flex lg:flex-col lg:-mt-5 lg:mr-6 lg:items-end">
          <p className="lg:mr-[14.3rem]">
            {propiedades.operacion.charAt(0).toUpperCase() +
              propiedades.operacion.slice(1)}
          </p>
          <p className="lg:mr-[10.8rem]">
            {propiedades.moneda} {propiedades.precio}
          </p>

          <div className="flex justify-between mt-3 lg:mr-[11.4rem]">
            <div
              className={`bg-primary w-fit px-2 rounded-md font-semibold text-xl flex items-center ${
                propiedades.tipo.toLowerCase() === "departamento"
                  ? "lg:-mr-20"
                  : "lg:mr-4"
              }`}
            >
              <MdOutlineRealEstateAgent />{" "}
              <p className="ml-1">{propiedades.tipo}</p>
            </div>
          </div>
          <div className="hidden lg:block">
            <p className="my-3 font-semibold">Comunicate con nosotros</p>
            <Form />
          </div>
        </div>
      </div>
      <div className="mt-5 ">
        <p className="text-2xl font-bold bg-primary -mx-3 pl-3 py-2">
          Acerca de la propiedad
        </p>
        <p className="mt-4">{propiedades.descripcion}</p>
        <PropertyDetailText />
      </div>
      <div className="my-6 flex flex-wrap">
        {propiedades.ambientes && (
          <div className="text-lg bg-primary font-semibold px-1 w-fit flex items-center mx-4 my-2">
            <FaHouse />
            <p className="ml-2">
              {propiedades.ambientes > 1
                ? `${propiedades.ambientes} ambientes`
                : "1 ambiente"}
            </p>
          </div>
        )}
        {propiedades.dormitorios && propiedades.dormitorios > 0 && (
          <div className="text-lg bg-primary font-semibold px-1 w-fit flex items-center mx-4 my-2">
            <IoIosBed />
            <p className="ml-2">
              {propiedades.dormitorios > 1
                ? `${propiedades.dormitorios} dormitorios`
                : "1 dormitorio"}
            </p>
          </div>
        )}
        {propiedades.baños && propiedades.baños > 0 && (
          <div className="text-lg bg-primary font-semibold px-1 w-fit flex items-center mx-4 my-2">
            <FaBath />
            <p className="ml-2">
              {propiedades.baños > 1 ? `${propiedades.baños} baños` : "1 baño"}
            </p>
          </div>
        )}
        {propiedades.mtsCubiertos && (
          <div className="text-lg bg-primary font-semibold px-1 w-fit flex items-center mx-4 my-2">
            <IoIosResize />
            <p className="ml-1">{propiedades.mtsCubiertos} Mts Cubiertos</p>
          </div>
        )}
        {propiedades.antiguedad && (
          <div className="text-lg bg-primary font-semibold px-1 w-fit flex items-center mx-4 my-2">
            <CiClock1 />
            <p className="ml-1">{propiedades.antiguedad} de antigüedad</p>
          </div>
        )}
      </div>
      <PropertyMap propiedades={propiedades} />
      <div className="lg:hidden">
        <p className="text-xl font-bold">¿Te interesa la propiedad?</p>
        <p className="my-3">Comunicate con nosotros</p>
        <Form />
      </div>
    </main>
  );
}
