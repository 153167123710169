// FIREBASE
import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PropertyList from "../propertyList/propertyList";

export default function PropertyListContainer() {
  const [propiedades, setPropiedades] = useState([]);
  const [btnSelected, setBtnSelected] = useState("Todos");
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const { id } = useParams();

  const handleClick = (categoria) => {
    setBtnSelected(categoria);
  };

  const getPropiedades = useMemo(async () => {
    const q = query(collection(db, "propiedades"));
    const docs = [];

    let querySnapshot;

    // Utiliza la caché si está disponible
    const options = {
      source: "cache",
    };

    try {
      if (id) {
        querySnapshot = await getDocs(
          query(q, where("venta", "==", id)),
          options
        );
      } else {
        querySnapshot = await getDocs(q, options);
      }

      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });

      // Filtra propiedades según el tipo de operación seleccionado
      const filteredPropiedades = docs.filter((propiedad) => {
        const matchesOperation =
          btnSelected === "Todos" ||
          (btnSelected === "Venta" && propiedad.operacion === "venta") ||
          (btnSelected === "Alquiler" && propiedad.operacion === "alquiler");
        const matchesPropertyType =
          selectedPropertyType === "" ||
          propiedad.tipo === selectedPropertyType;
        const matchesLocation =
          selectedLocation === "" || propiedad.ciudad === selectedLocation;

        return matchesOperation && matchesPropertyType && matchesLocation;
      });

      setPropiedades(filteredPropiedades);
    } catch (error) {
      console.error("Error al obtener propiedades:", error);
    }
  }, [id, btnSelected, selectedPropertyType, selectedLocation]);

  return (
    <>
      <div
        className="bg-primary mb-5 wow fadeIn py-4 flex flex-wrap mt-12 md:mt-0 lg:mt-0"
        data-wow-delay="0.1s"
      >
        <div className="container lg:mx-auto mx-4">
          <div className="grid grid-cols-1 md:grid-cols-10 gap-8">
            <div className="md:col-span-7">
              <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-8 gap-3">
                <div className="col-span-3 md:col-span-1">
                  <select
                    className="w-full border-0 py-3 px-2 rounded-md"
                    value={selectedPropertyType}
                    onChange={(e) => setSelectedPropertyType(e.target.value)}
                  >
                    <option value="">Tipo de propiedad</option>
                    <option value="Casa">Casa</option>
                    <option value="Departamento">Departamento</option>
                    <option value="Lotes">Lotes</option>
                    <option value="Local">Local</option>
                  </select>
                </div>
                <div className="col-span-3 md:col-span-1">
                  <select
                    className="w-full border-0 py-3 px-2 rounded-md"
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                  >
                    <option value="">Ubicación</option>
                    <option value="Don Orione">Don Orione</option>
                    <option value="Lomas de Zamora">Lomas de Zamora</option>
                    <option value="Temperley">Temperley</option>
                    <option value="Monte Grande">Monte Grande</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="md:col-span-3 flex items-center justify-center">
              <button
                className="btn font-semibold text-white bg-orange-500 rounded-md border-0 w-full py-3 px-4"
                onClick={() => getPropiedades()}
              >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 mx-6 flex flex-col">
        <h1 className="text-4xl font-bold">Propiedades</h1>
        <p className="mt-6 text-gray-600">Propiedades Disponibles:</p>

        <div className="flex lg:justify-end mt-8 lg:mt-[-1.9em]">
          <button
            className={`${
              btnSelected === "Todos"
                ? "bg-primary text-white"
                : "border border-primary"
            } rounded px-4 py-2 mr-2 hover:bg-primary hover:text-white transition duration-400 ease-in-out`}
            onClick={() => handleClick("Todos")}
          >
            Todos
          </button>
          <button
            className={`${
              btnSelected === "Venta"
                ? "bg-primary text-white"
                : "border border-primary"
            } rounded px-4 py-2 mr-2 hover:bg-primary hover:text-white transition duration-400 ease-in-out`}
            onClick={() => handleClick("Venta")}
          >
            Venta
          </button>

          <button
            className={`${
              btnSelected === "Alquiler"
                ? "bg-primary text-white"
                : "border border-primary"
            } rounded px-4 py-2 mr-2 hover:bg-primary hover:text-white transition duration-400 ease-in-out`}
            onClick={() => handleClick("Alquiler")}
          >
            Alquiler
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">
        <PropertyList propiedades={propiedades} />
      </div>
    </>
  );
}
