import Logo from "./logo.png";
import { MdOutlineMail } from "react-icons/md";
import CPMCLZ from "./CPMCLZ.png";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="bg-primary py-4 text-white">
        <div className="container px-4 mx-auto">
          <div className="-mx-4 flex flex-wrap justify-between">
            <div className="px-4 my-4 w-full xl:w-1/5">
              <a href="/" className="block w-56 mb-2">
                <img
                  src={Logo}
                  alt="Logo Natalia Chávez"
                  className="w-9/12 mx-auto"
                ></img>
              </a>
            </div>

            <div className="px-4 my-4 w-full xl:w-1/5">
              <Link
                to="http://www.cmcplz.com.ar/"
                className="block w-56 mb-2"
                target="_blank"
              >
                <img
                  src={CPMCLZ}
                  alt="Colegio Público de Martilleros y Corredores de Lomas de Zamora"
                  className="w-full mx-auto"
                ></img>
              </Link>
              <p className="text-justify">Matricula nro. 4298</p>
            </div>

            <div className="px-4 my-4 w-full sm:w-auto">
              <div>
                <h2 className="inline-block text-2xl pb-4 mb-4 border-b-4 border-orange-600">
                  Contacto
                </h2>
              </div>
              <ul className="leading-8  lg:flex-row">
                <Link to="https://walink.co/5e3deb" target="_blank">
                  <li className="flex items-center">
                    <FaWhatsapp className="mr-3" />
                    <span className="lg:mr-3">11 3214-7162</span>
                  </li>
                </Link>
                <li className="mt-2 lg:mt-0 flex items-center">
                  <MdOutlineMail className="mr-3" />
                  <span>info@nataliachavez.com.ar</span>
                </li>
              </ul>
            </div>

            <div className="px-4 my-4 w-full sm:w-auto xl:w-1/5">
              <div>
                <h2 className="inline-block text-2xl pb-4 mb-4 border-b-4 border-orange-600">
                  Nuestras redes
                </h2>
              </div>
              <Link
                className="inline-flex items-center justify-center h-8 w-8 border border-gray-100 rounded-full mr-1 hover:text-orange-100"
                to="https://www.instagram.com/nataliachavezneg.inm/"
                target="_blank"
              >
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                </svg>
              </Link>
              <Link
                className="inline-flex items-center justify-center h-8 w-8 border border-gray-100 rounded-full mr-1 hover:text-orange-100"
                to="https://www.facebook.com/nataliachavezneg.inm/"
                target="_blank"
              >
                <svg
                  className="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="bg-primary py-4 text-gray-100">
        <div className="container mx-auto px-4">
          <div className="-mx-4 flex flex-wrap justify-between">
            <div className="px-4 w-full text-center sm:w-auto sm:text-left">
              © 2024 Natalia Chávez
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
