// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBVePWlZPO49T04VtfsEBRxCOe31YS8YF0",
  authDomain: "natalia-chavez.firebaseapp.com",
  projectId: "natalia-chavez",
  storageBucket: "natalia-chavez.appspot.com",
  messagingSenderId: "840895252873",
  appId: "1:840895252873:web:04247ae622fba1d8f07958"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
