import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import NavBar from "./components/navBar/navBar";
import Footer from "./components/footer/footer";
import PropertyDetailContainer from "./pages/propertyDetailContainer/propertyDetailContainer";

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/propiedad/:id" element={<PropertyDetailContainer />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
