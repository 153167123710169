import React from "react";
import CertifiedAgent from "../../components/certifiedAgent/certifiedAgent";
import PropertyListContainer from "../../components/propertyListContainer/propertyListContainer";
import SliderHome from "../../components/sliders/sliderHome/sliderHome";

export default function Home() {
  return (
    <main>
      <div className="mt-24 md:flex">
        <div className="md:w-1/2 md:order-last">
          <SliderHome />
        </div>
        <div className="md:w-1/2 flex m-10 flex-col md:mx-2">
          <h1 className="text-4xl font-bold mb-4">
            Encontrá tu <span className="text-primary">próximo hogar</span> para
            vos y tu familia
          </h1>
          <p className="mb-4 text-gray-500">
            No sólo vendemos casas, te acompañamos en el camino de encontrar un
            hogar. Nuestros servicios son ALQUILER , VENTA, TASACIONES Y
            ASESORAMIENTO.
          </p>
          <button
            type="button"
            className="text-white cursor-default bg-primary   font-medium rounded-lg text-sm px-5 py-2.5 self-start"
          >
            Comenzá a buscar
          </button>
        </div>
      </div>

      <PropertyListContainer />
      <CertifiedAgent />
    </main>
  );
}
