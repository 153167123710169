//Componente en el que tiene texto por defecto para descripcion de propiedad

export default function PropertyDetailTextDefault() {
  return (
    <>
      <p className="my-2">
        No dudes en consultarnos, y sí esta propiedad no se ajusta a tu
        búsqueda, contanos que necesitas, que estamos para ayudarte.
      </p>
      <p className="my-2">Natalia Chávez Negocios Inmobiliarios.</p>
      <p className="my-2">Whatsapp 11 3214-7162.</p>
      <p className="mt-6">
        Operación supeditada al cumplimiento por parte del propietario a
        tramitar el Código de Oferta de Transferencia de Inmuebles (COTI).
        Régimen General 2371/07.
      </p>
      <p className="my-2">
        Las medidas e información brindadas deberán revalidarse con la
        documentación correspondiente. No formando parte contractual ni
        comprometiendo a nuestra oficina. Fotografías no vinculantes ni
        contractuales.
      </p>
    </>
  );
}
